<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005732')" prop="ymsWarehouseId">
              <Select v-model="pageParams.ymsWarehouseId" filterable>
                <Option v-for="item in warehouseList" :key="item.ymsWarehouseId" :value="item.ymsWarehouseId">{{ item.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1003585')">
              <Cascader
                v-model="shippingMethodModel"
                :load-data="loadData"
                @on-change="changeShippingMethod"
                @on-visible-change="showShippingData"
                :data="shippingMethodData"
                filterable
                change-on-select>
              </Cascader>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <dropDownFilter
              ref="dropDownFilter"
              inputType="textarea"
              :selectStyle="{width: '120px'}"
              :dropDownInputList="dropDownInputList"
              :defaultSelectValue="defaultSelectValue"
              @changeInput="changeInput"
              @updateInputValue="updateInputValue">
            </dropDownFilter>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item label="SKU：" prop="sku">
              <Input type="textarea" clearable :placeholder="$t('key1004740')" v-model.trim="pageParams.sku"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1002124')" prop="searchValue">
              <Input type="textarea" clearable :placeholder="$t('key1006146')" v-model.trim="pageParams.searchValue"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1001977')" prop="buyerCountryCodeList">
              <Select
                v-model="pageParams.buyerCountryCodeList"
                @on-change="countryChange"
                filterable
                multiple
                :placeholder="$t('key1001016')">
                <Option
                  v-for="(item, index) in countrysData"
                  :key="index"
                  :label="item.enName"
                  :value="item.twoCode">{{ item.enName }}
                </Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1003562')" prop="outboundType">
              <Select v-model="pageParams.outboundType" filterable>
                <Option v-for="(item,index) in outboundTypeList" :key="index" :value="item.value" :label="item.name">
                  {{ item.name }}
                </Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1000921')">
              <Date-picker
                style="width:100%"
                type="datetimerange"
                @on-clear="resetDate('created')"
                transfer
                @on-change="(value)=>getDateValue(value, 'created')"
                :clearable="clearAble"
                :options="dateOptions"
                format="yyyy-MM-dd HH:mm:ss"
                placement="bottom-end"
                :placeholder="$t('key1000273')"
                :value="createdTimeArr">
              </Date-picker>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1001555')" prop="packageCode">
              <Input :placeholder="$t('key1006142')" clearable v-model.trim="pageParams.packageCode"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1002559')" prop="orderNo">
              <Input :placeholder="$t('key1006143')" clearable v-model.trim="pageParams.orderNo"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1005070')" prop="buyerName">
              <Input :placeholder="$t('key1006144')" clearable v-model.trim="pageParams.buyerName"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1005119')" prop="buyerAccountId">
              <Input :placeholder="$t('key1006145')" clearable v-model.trim="pageParams.buyerAccountId"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1002385')" prop="source">
              <Select v-model="pageParams.source" filterable>
                <Option
                  v-for="(item,index) in sourceList"
                  :key="index"
                  :value="item.enName"
                  :label="item.enName">{{ item.enName }}
                </Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1006183')" prop="customerNumber">
              <Input v-model.trim="pageParams.customerNumber" clearable/>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1006184')" prop="mobileImieCode">
              <Input v-model.trim="pageParams.mobileImieCode" clearable/>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1003681')" prop="packageProcessStatus">
              <Select v-model="pageParams.packageProcessStatus" multiple @on-change="outListStatusChange" filterable>
                <Option
                  v-for="item in outListStatusList"
                  :value="item.value"
                  :key="item.value"
                  :label="item.label"> {{ item.label }}
                </Option>
              </Select>
            </Form-item>
          </Col>
          <!-- 出库时间 -->
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1003299')">
              <Date-picker
                style="width:100%"
                type="datetimerange"
                @on-clear="resetDate('despatch')"
                transfer
                @on-change="(value)=>getDateValue(value, 'despatch')"
                :clearable="clearAble"
                :options="dateOptions"
                format="yyyy-MM-dd HH:mm:ss"
                placement="bottom-end"
                :placeholder="$t('key1000273')"
                :value="despatchTimeArr">
              </Date-picker>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <Form-item :label="$t('key1003468')">
              <Date-picker
                style="width:100%"
                type="datetimerange"
                @on-clear="resetDate('pay')"
                @on-change="(value)=>getDateValue(value, 'pay')"
                transfer
                :clearable="clearAble"
                :options="dateOptions"
                format="yyyy-MM-dd HH:mm:ss"
                placement="bottom-end"
                :placeholder="$t('key1000273')"
                :value="payTimeArr">
              </Date-picker>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <div class="filterItem">
              <Form-item :label="$t('key1001133')">
                <local-tabButton :tabButtonList="tabsData" @selectStatus="selectTabsData"></local-tabButton>
              </Form-item>
            </div>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <div class="filterItem">
              <Form-item :label="$t('key1003546')">
                <local-tabButton :tabButtonList="tabsData1" @selectStatus="selectTabsData1"></local-tabButton>
              </Form-item>
            </div>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <div class="filterItem max_filterItem">
              <Form-item :label="$t('key1006150')">
                <local-tabButton :tabButtonList="tabsData3" @selectStatus="selectTabsData3"></local-tabButton>
              </Form-item>
            </div>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-show="moreFilter">
            <div class="filterItem max_filterItem">
              <Form-item :label="$t('key1006151')">
                <local-tabButton :tabButtonList="tabsData4" @selectStatus="selectTabsData4"></local-tabButton>
              </Form-item>
            </div>
          </Col>
          <Col style="width: 200px;">
            <Button @click="reset" icon="md-refresh" class="mr10">{{ $t('key1000095') }}</Button>
            <Button type="primary" @click="search" icon="md-search">{{ $t('key1000274') }}</Button>
          </Col>
        </Row>
        <Row type="flex" justify="end" :gutter="gutterItem" class="filter_options_button">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" style="text-align: right;">
            <Button @click="toggleMoreFilter">{{ moreFilterMes }}
              <Icon :type="moreFilterIcon"></Icon>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <div class="mr15 sort_options_box">
        <span>{{ $t('key1002312') }}</span>
        <Button-group>
          <template v-for="(item, index) in buttonGroupModel">
            <Button v-if="item.selected" type="primary" @click="modifyTheSort(index, item.status)" :key="index">
              {{ item.title }}
              <Icon type="md-arrow-up" v-if="item.status && item.selected"></Icon>
              <Icon type="md-arrow-down" v-if="!item.status && item.selected"></Icon>
            </Button>
            <Button v-if="!item.selected" @click="modifyTheSort(index, item.status)" :key="index">
              {{ item.title }}
              <Icon type="md-arrow-up" v-if="item.status && item.selected"></Icon>
              <Icon type="md-arrow-down" v-if="!item.status && item.selected"></Icon>
            </Button>
          </template>
        </Button-group>
      </div>
      <Dropdown @on-click="changeExportBtn" class="mr15" v-if="getPermission('packageInfo_export')">
        <Button type="primary">
          <span>{{ $t('key1002365') }}</span>
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem name="1">{{ $t('key1002366') }}</DropdownItem>
          <DropdownItem name="2">{{ $t('key1002367') }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <!--新增出库单-->
      <Button class="mr15" type="primary" @click="addSupplierOrder">{{ $t('key1006187') }}</Button>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        ref="selection"
        highlight-row
        border
        max-height="530"
        :loading="tableLoading"
        :columns="columns"
        :data="printData"
        @on-selection-change="getSelectValue">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="curPage"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <!--导出数据字段弹窗-->
    <exportFieldsModal
      ref="exportFields"
      :exportFieldsTitle="$t('key1006185')"
      paramKeyColums="supplierPackageInfoColumns"
      :customFieldExportList="customFieldExportList"
      @exportFieldsDataBtn="exportFieldsDataBtn">
    </exportFieldsModal>
    <!--作废出库单异常提示弹窗-->
    <Modal v-model="nullifyModal" :transfer="false" class-name="tips_style" :title="$t('key1006186')">
      <div class="tips_center" v-for="(item,index) in nullifyList" :key="index">
        <h4 class="title">{{ $t('key1001555') + item.packageCode }}</h4>
        <p class="txt">{{ item.errorMsg }}</p>
      </div>
      <template #footer>
        <div style="text-align: center;">
          <Button @click="nullifyModal = false">{{ $t('key1000205') }}</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import dropDownFilter from "@/components/common/dropDownFilter";
import {OUTBOUNDTYPELIST} from "@/utils/enum";
import exportFieldsModal from "@/components/common/exportFieldsModal";
import {pageJump} from "@/utils/common";

export default {
  name: "searchPackage",
  mixins: [Mixin],
  components: {
    dropDownFilter,
    exportFieldsModal
  },
  data() {
    let v = this;
    return {
      sourceList: [], // 组织列表
      defaultSelectValue: 1,
      dropDownInputList: [
        {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002920'), value: 1, placeholder: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006188'), parameterKey: 'trackingNumberList'},
        {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003639'), value: 2, placeholder: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006189'), parameterKey: 'thirdPartyNoList'}
      ],
      packageIds: [], // 详情参数 Start
      packageModel: false,
      packageDetailsStatus: true,
      packageId: null,
      timestamp: null, // End
      totalPage: 0,
      total: 0,
      curPage: 1,
      payTimeArr: [],
      createdTimeArr: [],
      despatchTimeArr: [],
      pageParamsStatus: false,
      pageParamsPrototype: null, // 重置查询条件
      tabsData3: [
        {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096'), selected: true, value: -1},
        {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000328'), selected: false, value: 1},
        {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000329'), selected: false, value: 0}
      ],
      tabsData4: [
        {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096'), selected: true, value: -1},
        {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000328'), selected: false, value: 1},
        {title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000329'), selected: false, value: 0}
      ],
      tabsData: [
        {selected: true, value: null, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
        {selected: false, value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004273')},
        {selected: false, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004272'), value: 0}
      ],
      tabsData1: [
        {selected: true, value: null, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')},
        {selected: false, value: 1, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004273')},
        {selected: false, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004272'), value: 0}
      ],
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        source: null,
        customerNumber: null,
        mobileImieCode: null,
        status: 0,
        hasCarrierWeight: -1, // 1有 0无 -1全部
        hasPostage: -1, // 1有 0无 -1全部
        hasTrackingNumber: null,
        hasThirdPartyNo: null,
        getLabelStatus: null,
        platFormId: null,
        saleAccountIds: [],
        packageCode: null,
        waybillNo: null,
        startDespatchTime: null,
        endDespatchTime: null,
        payStartTime: null,
        payEndTime: null,
        startCreatedTime: null,
        endCreatedTime: null,
        buyerAccountId: null,
        buyerCountryCodeList: [],
        buyerName: null,
        merchantCarrierId: null,
        merchantShippingMethodId: null,
        orderBy: 'createdTime',
        orderNo: null,
        upDown: 'down',
        webstoreItemId: null,
        platformId: null,
        sku: null,
        searchValue: null, // 搜索内容
        packageProcessStatus: null, // 出库单状态
        trackingNumberList: null,
        thirdPartyNoList: null,
        outboundType: 0,
        warehouseId: v.getWarehouseId(), // 仓库
        ymsWarehouseId: 'null'
      },
      buttonGroupModel: [
        {type: 'createdTime', selected: true, status: false, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001645')},
        {type: 'despatchTime', selected: false, status: true, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006190')},
        {type: 'payTime', selected: false, status: true, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006155')},
        {type: 'packageCode', selected: false, status: true, title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006156')}
      ],
      platformArr: [],
      clearAble: true,
      resetStatus: true,
      printData: [],
      columns: [
        {
          type: 'selection',
          width: 60,
          fixed: 'left',
          align: 'center'
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000966'),
          key: 'packageCode',
          fixed: 'left',
          align: 'center',
          minWidth: 160,
          render: (h, params) => {
            return h('Alink', {
              props: {
                AlinkShow: v.getPermission('wmsPicking_querySalesPickingDetail'),
                linkName: params.row.packageCode,
                Vicon: 'md-hand',
                ViconSize: '16px',
                ViconShow: params.row.isImport === 1
              },
              on: {
                AlinkBtn: () => {
                  v.$router.push({
                    path: '/wms/searchPackageDetails',
                    query: {
                      pickingNo: params.row.packageCode,
                      workShow: 'detail',
                      workType: "sellStock"
                    }
                  });
                }
              }
            });
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002570'),
          align: 'center',
          minWidth: 130,
          key: 'outboundType',
          render: (h, params) => {
            let text = '';
            let status = params.row.outboundType;
            v.outboundTypeList.map((item) => {
              if (item.value === status) {
                text = item.name;
              }
            });
            let list = [
              h('span', text),
              status === 4 ? h('Tooltip', {
                  props: {
                    transfer: true,
                    maxWidth: 250,
                    placement: 'top',
                    content: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006191')
                  },
                  style: {
                    marginLeft: '5px'
                  }
                },
                [
                  h('Icon', {
                    props: {
                      type: 'md-help-circle',
                      size: 17
                    },
                    style: {
                      cursor: 'pointer',
                      color: '#7F7F7F'
                    }
                  })
                ]) : ''
            ]
            return h('div', {class: 'flex align-items-center justify-content-center'}, list);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003350'),
          key: 'warehouseName',
          minWidth: 130,
          align: 'center',
          render(h, params) {
            return h('span', v.getWarehouseName(params.row.ymsWarehouseId));
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002537'),
          key: 'packageProcessStatus',
          minWidth: 170,
          align: 'center',
          render: (h, params) => {
            let status = params.row.packageProcessStatus;
            let name = '';
            v.outListStatusList.map((item) => {
              if (item.value === status) {
                name = item.label;
              }
            });
            return h('div', name);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006192') + '/' + aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006193'),
          key: 'source',
          minWidth: 170,
          align: 'center',
          render(h, params) {
            let customerNumber = params.row.customerNumber;
            let source = params.row.source;
            return h('div', {}, [h('p', source), h('p', customerNumber)]);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006157'),
          key: 'buyerName',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('div', {
                style: {
                  color: '#0054A6'
                }
              }, params.row.buyerAccountId), h('div', {
                style: {
                  color: '#ff3300'
                }
              }, params.row.buyerName)
            ]);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001829'),
          key: 'buyerCountryCode',
          align: 'center',
          width: 100
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003045'),
          key: 'merchantShippingMethodId',
          width: 250,
          align: 'center',
          render: (h, params) => {
            if (params.row.carrierName === null || params.row.carrierName === '') {
              return '';
            } else {
              return h('div', params.row.carrierName + ' > ' + params.row.carrierShippingMethodName);
            }
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006194'),
          key: 'trackingNumber',
          width: 180,
          align: 'center',
          render(h, params) {
            return h('div', {}, [
              h('p', params.row.trackingNumber),
              h('p', params.row.virtualTrackingNumber),
              h('p', params.row.thirdPartyNo)
            ]);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006195'),
          key: 'carrierWeight',
          width: 200,
          align: 'center',
          render(h, params) {
            let weight = params.row.carrierWeight ? params.row.carrierWeight + ' ' + 'g' : '';
            let postage = params.row.postage ? params.row.postage + ' ' + params.row.postageCurrency : '';
            return h('div', {}, [h('p', weight), h('p', postage)]);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001851'),
          key: 'time',
          align: 'center',
          width: 250,
          render: (h, params) => {
            let payTime = v.$uDate.dealTime(params.row.payTime, 'fulltime');
            let createdTime = v.$uDate.dealTime(params.row.createdTime, 'fulltime');
            let despatchTime = v.$uDate.dealTime(params.row.despatchTime, 'fulltime');
            return h('div', {
              style: {
                textAlign: 'left'
              }
            }, [
              h('p', {style: {margin: '3px 0 3px 0'}}, `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000921')}${createdTime}`),
              h('p', {style: {margin: '3px 0 3px 0'}}, `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003468')}${payTime}`),
              h('p', {style: {margin: '3px 0 3px 0'}}, `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003299')}${despatchTime}`)
            ]);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000283'),
          key: 'options',
          align: 'center',
          fixed: 'right',
          width: 200,
          render: (h, params) => {
            let status = params.row.packageProcessStatus; // 已打印的状态，才有打印面单
            // 出库类型 为 三方仓备货（无面单类型时），不显示打印面单按钮
            let outboundType = params.row.outboundType;
            let arr = [4, 5, 7]; // 已打印、出库完成、包装完成
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002824'),
                    show: arr.includes(status) && ![2, 3].includes(outboundType),
                    clickFn: () => {
                      v.expressSheet(params.row.packageId);
                    }
                  },
                  {
                    text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006196'),
                    show: arr.includes(status) && ![2, 3].includes(outboundType),
                    clickFn: () => {
                      v.expressSheet(params.row.packageId, 'down');
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ], // 以下为修改包裹详情页后新增变量
      outListStatusList: [
        {value: 1, label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001274')},
        {value: 2, label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001278')},
        {value: 3, label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001393')},
        {value: 4, label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001264')},
        {value: 6, label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001394')},
        {value: 7, label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001291')},
        {value: 8, label: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001286')},
      ],
      cancelTalg: false,
      packageIdsList: [],
      cancelConter: {},
      outboundTypeList: OUTBOUNDTYPELIST,
      nullifyModal: false,
      nullifyList: [],
      warehouseList: [],
      exportType: '1',
      customFieldExportList: [
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000646'),
          titleKey: 'basicInfoColums',
          selectList: [], // 选中导出字段的key
          checkBoxList: [
            {paramKey: 'packageCode', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000132')},
            {paramKey: 'salesRecordNumber', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006197')},
            {paramKey: 'merchantCarrierId', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000197')},
            {paramKey: 'merchantShippingMethodId', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003045')},
            {paramKey: 'trackingNumber', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002920')},
            {paramKey: 'thirdPartyNo', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003639')},
            {paramKey: 'buyerAccountId', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005185')},
            {paramKey: 'buyerName', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006198')},
            {paramKey: 'buyerCountryCode', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001921')},
            {paramKey: 'buyerState', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001830')},
            {paramKey: 'buyerCity', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001831')},
            {paramKey: 'buyerAddress', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003192')},
            {paramKey: 'buyerPostalCode', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006199')},
            {paramKey: 'buyerPhone', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002136')},
            {paramKey: 'buyerMobile', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002135')},
            {paramKey: 'printTime', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006200')},
            {paramKey: 'printBy', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006201')},
            {paramKey: 'despatchTime', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004075')},
            {paramKey: 'despatchBy', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004065')},
            {paramKey: 'totalWeight', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006202')},
            {paramKey: 'postage', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005095')},
            {paramKey: 'carrierWeight', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006203')},
            {paramKey: 'source', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006204')},
            {paramKey: 'customerNumber', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002457')},
            {paramKey: 'sku', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006205')},
            {paramKey: 'quantity', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002573')},
            {paramKey: 'mobileImieCodes', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1006206')},
          ]
        }
      ],
    };
  },
  created() {
    let v = this;
    v.initFun();
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    filterKeyList() {
      let parameterKeyList = this.dropDownInputList.map((item) => {
        return item.parameterKey
      });
      return parameterKeyList;
    }
  },
  methods: {
    // 初始化接口数据
    initFun() {
      let v = this;
      v.warehouseList = [];
      v.setDefaultTimerParams('three_months', '', 'created');
      (async () => {
        await v.getBelongingWarehouseData(v.userInfo.merchantId).then((data) => {
          if (data && data.length > 0) {
            v.warehouseList = data;
            let list = data.filter((item) => {
              return item.isFirst === 1
            });
            v.pageParams.ymsWarehouseId = list.length > 0 ? list[0].ymsWarehouseId : 'null';
            v.warehouseList.unshift({
              ymsWarehouseId: 'null',
              name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000096')
            })
          }
        });
        await v.getCountrysData();
        await v.getSourceList();
        await v.search();
      })();
      v.pageParamsPrototype = v.deepCopy(v.pageParams);
    },
    // 获取来源渠道数据
    getSourceList() {
      let v = this;
      v.axios.get(api.get_organizationSource_query).then(response => {
        if (response.data.code === 0) {
          v.sourceList = response.data.datas;
        }
      });
    },
    // 获取列表数据
    getSearchResult() {
      let v = this;
      v.printData = [];
      v.total = 0;
      v.tableLoading = true;
      v.axios.post(api.post_packageInfo_query, v.handleParameter()).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;

          function handleFun() {
            v.printData = [];
            v.pageParamsStatus = false;
            v.$nextTick(function () {
              v.total = 0;
              v.totalPage = 0;
              v.tableLoading = false;
            });
          }

          if (data) {
            let list = data.list || [];
            let merchantShippingMethodIdPos = [];
            if (list.length > 0) {
              list.forEach((n, i) => {
                n.carrierName = null;
                n.carrierShippingMethodName = null;
                n.index = i + 1;
                merchantShippingMethodIdPos.push(n.merchantShippingMethodId);
              });
              if (merchantShippingMethodIdPos.length > 0) {
                v.GetQueryCarrierShippingName(JSON.stringify(merchantShippingMethodIdPos)).then((list) => {
                  if (list.length > 0) {
                    list.forEach((n, i) => {
                      list.forEach((m, t) => {
                        if (m.merchantShippingMethodId === n.shippingMethodId) {
                          m.carrierName = n.carrierName;
                          m.carrierShippingMethodName = n.carrierShippingMethodName;
                        }
                      });
                    });
                  }
                }).then(() => {
                  v.printData = list;
                  v.$nextTick(function () {
                    v.total = Number(data.total);
                    v.totalPage = Number(data.pages);
                    v.tableLoading = false;
                  });
                });
              }
            } else {
              handleFun();
            }
          }
        } else {
          handleFun()
        }
      });
    },
    reset() {
      let v = this;
      v.$refs['pageParams'].resetFields();
      v.shippingMethodModel = [];
      v.payTimeArr = [];
      v.createdTimeArr = [];
      v.despatchTimeArr = [];
      v.resetDate('despatch');
      v.resetDate('pay');
      v.resetDate('created');
      v.selectTabsData(0, null);
      v.selectTabsData1(0, null);
      v.pageParams.merchantCarrierId = null;
      v.pageParams.merchantShippingMethodId = null;
      v.selectTabsData3(0, -1);
      v.selectTabsData4(0, -1);
      v.changeInput(1);
      v.setDefaultTimerParams('three_months', '', 'created');
      v.search();
    },
    // 查询按钮
    search() {
      let v = this;
      v.curPage = 1;
      v.pageParams.pageNum = 1;
      v.pageParamsStatus = true;
    },
    // 选择物流方式
    changeShippingMethod(value, data) {
      let v = this;
      if (value.length === 0) {
        v.pageParams.merchantCarrierId = null;
        v.pageParams.merchantShippingMethodId = null;
      } else if (value.length === 1) {
        v.pageParams.merchantCarrierId = value[0];
        v.pageParams.merchantShippingMethodId = null;
      } else if (value.length === 2) {
        v.pageParams.merchantCarrierId = value[0];
        v.pageParams.merchantShippingMethodId = value[1][0];
      }
    },
    // 重置时间
    resetDate(type) {
      let v = this;
      if (type === 'despatch') {
        v.pageParams.startDespatchTime = null;
        v.pageParams.endDespatchTime = null;
      } else if (type === 'pay') {
        v.pageParams.payStartTime = null;
        v.pageParams.payEndTime = null;
      } else if (type === 'created') {
        v.pageParams.startCreatedTime = null;
        v.pageParams.endCreatedTime = null;
      }
    },
    // 获取日期返回值
    getDateValue(value, type) {
      let v = this;
      if (value.length === 0 || !value[0]) {
        v.resetDate(type);
      } else {
        if (value[0] !== '') {
          let timeList = v.defaultTimePeriod(value);
          // 出库时间
          if (type === 'despatch') {
            v.despatchTimeArr = v.defaultTimePeriod(value,false);
            v.pageParams.startDespatchTime = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
            v.pageParams.endDespatchTime = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
          }
          // 付款时间
          else if (type === 'pay') {
            v.payTimeArr = v.defaultTimePeriod(value,false);
            v.pageParams.payStartTime = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
            v.pageParams.payEndTime = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
          }
          // 创建时间
          else if (type === 'created') {
            v.createdTimeArr = v.defaultTimePeriod(value,false);
            v.pageParams.startCreatedTime = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
            v.pageParams.endCreatedTime = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
          }
        }
      }
    },

    // 选择导出数据类型  1 按选中导出 2 按查询结果导出
    changeExportBtn(value) {
      this.exportType = value;
      if (value === '1') {
        if (this.packageIds.length <= 0) {
          this.$Message.warning(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002368'));
          return false;
        }
      } else {
        if (this.printData.length <= 0) {
          this.$Message.warning(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002369'));
          return false;
        }
      }
      this.$refs['exportFields'].getExportFieldsSetting();
    },
    // 处理参数
    handleParameter() {
      let v = this;
      let params = Object.assign({}, v.pageParams);
      params.outboundType = v.pageParams.outboundType === 0 ? null : v.pageParams.outboundType;

      let keyList = ['ymsWarehouseId'];
      for (let key of keyList) {
        params[key] = v.pageParams[key] === 'null' ? null : v.pageParams[key];
      }

      for (let key in params) {
        if (v.filterKeyList.includes(key)) {
          params[key] = v.sepCommasFn(params[key], [',', '，', '\n']);
        }
      }
      return params;
    },
    // 导出数据
    exportFieldsDataBtn() {
      let v = this;
      let query = v.handleParameter();
      query.timeZone = localStorage.getItem('timezoom') === undefined ? 8 : localStorage.getItem('timezoom');
      query.warehouseId = v.getWarehouseId();
      if (v.exportType === '1') {
        query.packageIds = v.packageIds;
      } else {
        query.packageIds = [];
      }
      v.$refs['exportFields'].saveExportFieldsSetting().then((value) => {
        if (value) {
          v.axios.post(api.post_packageInfo_export, query,
            {loading: true, loadingText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000213')}).then(res => {
            if (res.data.code === 0) {
              v.$refs['exportFields'].exportFieldsModal = false;
              let taskNumber = res.data.datas;
              setTimeout(() => {
                pageJump('/supplier.html#/exportTask?operateCode=' + taskNumber, '/yms-supplier-service', '_blank');
              }, 30)
            }
          });
        }
      });
    },
    // table获取所选值
    getSelectValue(value) {
      let v = this;
      v.packageIdsList = value;
      let pos = [];
      Promise.resolve(value.forEach((n, i) => {
        pos.push(n.packageId);
      })).then(() => {
        v.packageIds = pos;
      });
    },
    // 出库单状态选择
    outListStatusChange(val) {
      this.sltChangeProcess(val, 'packageProcessStatus');
    },
    // 多选模式下全部和选项的切换
    sltChangeProcess(val, string) {
      if (val.length > 1) {
        if (val[val.length - 1] === '*') {
          this.searchParams[string] = ['*'];
        } else {
          if (val.indexOf('*') > -1) {
            val.splice(val.indexOf('*'), 1);
            this.searchParams[string] = val;
          }
        }
      }
    },
    // 运单号
    selectTabsData(index, value) {
      let v = this;
      v.pageParams.hasTrackingNumber = value;
      v.tabsData.map((item, idx) => {
        item.selected = index === idx;
      });
    },
    // 物流商单号
    selectTabsData1(index, value) {
      let v = this;
      v.pageParams.hasThirdPartyNo = value;
      v.tabsData1.map((item, idx) => {
        item.selected = index === idx;
      });
    },
    // 是否有物流商重量
    selectTabsData3(index, value) {
      let v = this;
      v.pageParams.hasCarrierWeight = value;
      v.tabsData3.map((item, idx) => {
        item.selected = index === idx;
      });
    },
    // 是否有物流商运费
    selectTabsData4(index, value) {
      let v = this;
      v.pageParams.hasPostage = value;
      v.tabsData4.map((item, idx) => {
        item.selected = index === idx;
      });
    },
    // 打印面单
    /**
     * @param type string down 下载
     * */
    expressSheet(packageId, type) {
      let v = this;
      let obj = {
        packageIds: [packageId]
      };
      v.axios.post(api.post_packageInfo_rePrintExpressBill, JSON.stringify(obj), {loading: true, loadingText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000213')}).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            if (type === 'down') {
              data.forEach(i => {
                window.open(i.labelPath, '_blank');
              });
              return;
            }
            data.every((n, i) => {
              v.commonPrint(n.labelPath, n.packageCode);
              return true;
            });
          } else {
            v.$Message.warning(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003216'));
            return false;
          }
        }
      });
    },
    // 新增出库单
    addSupplierOrder() {
      // 判断是否有开启仓储管理设置
      this.getSupplierWarehouseSetting().then((val) => {
        if (val) {
          this.$router.push('/addManualSupplierOrder');
        }
      });
    },
    // 获取下拉选项筛选条件的值
    updateInputValue(data) {
      this.pageParams[data.key] = data.value;
    },
    // 改变默认下拉选项的值
    changeInput(value) {
      this.defaultSelectValue = value;
      this.$refs.dropDownFilter.inputValue = '';
      this.dropDownInputList.map((item) => {
        if (Object.keys(this.pageParams).includes(item.parameterKey)) {
          this.pageParams[item.parameterKey] = '';
        }
      });
    }
  },
  watch: {
    pageParamsStatus(n) {
      let v = this;
      if (n) {
        v.getSearchResult();
        v.pageParamsStatus = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.filter_box {
  .max_filterItem {
    /deep/ label {
      width: 140px !important;
    }
  }
}

/deep/ .tips_style {
  .ivu-modal-body {
    max-height: 600px;
    overflow-y: auto;
  }

  .tips_center {
    margin: 8px 0;

    .title {
      color: #333;
      font-weight: bold;
      margin-right: 5px;
    }

    .txt {
      color: #666;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}
</style>
